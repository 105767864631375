@import "palettes";
@import "theme";
@import "mixins";

.search_box {
  position: relative;
  margin: 20px 0;
  margin-left: auto;
  margin-right: auto;
  @include trim-v-margins;
  width: $page / 2;
  height: 40px;
  max-width: 100%;

  button {
    @include inline-flex-center;
    position: absolute;
    right: 0;
    width: 40px;
    height: 100%;
    background: none;
    border: none;
  }

  button:not([disabled]) {
    cursor: pointer;
  }

  .search_input {
    width: 100%;
    max-width: unset;
    height: 100%;
    margin: 0;
    padding-right: 40px;
  }
}
